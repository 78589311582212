import styled from 'styled-components';
import { Text } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';

// Need this empty wrapper for SchemaArticle script
const Wrapper = styled.div``;

const Header = styled.div`
  width: fit-content;
  padding-right: 16px;
  margin-left: -16px;
  background-color: white;
`;

const ImageWrapper = styled.div`
  margin-top: 0;
  padding: 0 !important;

  @media (min-width: ${breakpoints.m}) {
    margin-top: 38px;
  }
`;

const ImageFigure = styled.figure`
  margin: 0;
  width: calc(100% + 66px);
  position: relative;
  cursor: pointer;
  overflow: hidden;
  margin-left: -33px;
`;

const Image = styled.img`
  width: 100%;
  display: block;
  object-fit: cover;
  height: 110%;
  margin-bottom: -18px;
`;

const Title = styled(Text)`
  position: relative;
  margin-top: -41px;
  background: ${nafColor.signature.white};
  width: 100%;
  padding: 10px 15px 0;
  min-height: 50px;
  font-size: 2rem;

  @media (min-width: ${breakpoints.m}) {
    margin-left: 0;
    margin-top: -41px;
    padding: 24px 33px 0 16px;
    max-width: 512px;
    font-size: 3rem;
  }

  @media (min-width: ${breakpoints.l}) {
    margin-top: -68px;
  }
`;

const Ingress = styled(Text)`
  margin-top: 32px;
  margin-bottom: 65px;
`;

const Date = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.l}) {
    margin-bottom: 24px;
  }
`;

const NoMarginText = styled(Text)`
  margin: 0;
`;

const Logo = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 12px;
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;

  & > :not(:last-child) {
    margin-bottom: ${spacing.space32};
  }

  @media (min-width: ${breakpoints.l}) {
    flex-direction: row;
    align-items: flex-start;

    & > :not(:last-child) {
      margin-bottom: 20px;
      margin-right: ${spacing.space32};
    }
  }
`;

const DataCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${nafColor.primary.dew};
  align-self: stretch;
  margin-bottom: ${spacing.space20};
`;

const DataCardWrapper = styled.div`
  padding-top: ${spacing.space40};
  margin-left: ${spacing.space32};
  padding-bottom: ${spacing.space20};
`;

const DataCardHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const DataCardHeaderText = styled(Text)`
  margin-top: 0;
`;

const DataCardIconedElement = styled.div`
  display: flex;
  flex-direction: row;
  align-content: baseline;
  margin-bottom: ${spacing.space24};

  & svg {
    color: ${nafColor.primary.park};
    margin-top: ${spacing.space4};
  }
`;

const DataCardTextWrapper = styled.div`
  h5 {
    font-weight: 500;
  }

  p {
    font-size: 0.875rem;
    line-height: 0.875rem;
    margin-top: ${spacing.space4};
  }
`;

const StyledText = styled(Text)`
  margin: 0 ${spacing.space12};
`;

const Body = styled.div`
  margin-bottom: 40px;

  p {
    a:hover {
      color: ${nafColor.primary.park};
    }
  }
`;

const Byline = styled.div`
  font-style: italic;
`;

const TestList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const TestListElement = styled.li`
  list-style-type: none;

  a {
    margin-bottom: 24px !important;
    padding-bottom: 24px !important;
  }
`;

const HRLine = styled.hr`
  border: 0;
  border-top: 3px solid ${nafColor && nafColor.signature.yellow ? nafColor.signature.yellow : `#FFDB16`};
  width: 96px;
  margin: 40px 0 46px;
  color: ${nafColor && nafColor.signature.yellow ? nafColor.signature.yellow : `#FFDB16`};
`;

export default {
  ImageWrapper,
  ImageFigure,
  Image,
  Wrapper,
  Header,
  Title,
  Ingress,
  Date,
  NoMarginText,
  Logo,
  Data,
  DataCard,
  DataCardWrapper,
  DataCardHeaderWrapper,
  DataCardHeaderText,
  DataCardIconedElement,
  DataCardTextWrapper,
  StyledText,
  Body,
  Byline,
  TestList,
  TestListElement,
  HRLine,
};
